/* eslint-disable no-undef */
import axios from 'axios'
require('dotenv').config()
import router from '../../router'
/**
 * default headers shared by  configs
 * @type Object
 */
let baseurl = process.env.VUE_APP_API_URL
let admin_harsh = process.env.VUE_APP_DASHBOARD_HARSH

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  admin_harsh: admin_harsh,
}
/**
 * Main api config for ops-portal
 * @type Object axios configs
 * @TODO env url

 */
const api = axios.create({ baseURL: baseurl, headers: defaultHeaders })
api.interceptors.request.use(
  (config) => {
    if (localStorage.getItem('tehecaAuth') !== null) {
      const auth = JSON.parse(localStorage.getItem('tehecaAuth'))
      config.headers = {
        Authorization: `Bearer: ${auth.token}`,
        'X-Country-Code': 'UG',
        'X-Email': auth.user.email,
      }
    }
    return config
  },
  (error) => {
    if (error.response.status === 401) {
      if (localStorage.getItem('tehecaAuth') !== null) {
        localStorage.removeItem('tehecaAuth')
        router.push({ path: '/login' })
      }
    }
    Promise.reject(error)
  }
)

export { api }
