<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-5">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Login here</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                id="email"
                v-model="email"
                class="email"
                label="Email"
                name="email"
                type="text"
                :rules="emailRules"
                required
              />

              <v-text-field
                id="password"
                v-model="password"
                class="password"
                label="Password"
                name="password"
                type="password"
                :rules="passwordRules"
                required
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              id="login-btn"
              color="primary"
              depressed
              large
              class="login-btn"
              ripple
              @click="handleLogin"
              :disabled="getLoadingState"
            >
              Login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Login',
  data() {
    return {
      snackbar: false,
      color: 'success',
      actionColor: 'error',
      message: '',
      timeout: 2000,
      email: '',
      password: '',
      emailRules: [
        (value) => !!value || 'Email is required.',
        (value) => (value || '').length <= 30 || 'Max 30 characters',
        (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      ],
      passwordRules: [
        (value) => !!value || 'Required.',
        (value) => (value || '').length >= 6 || 'Min 6 characters',
      ],
    }
  },
  computed: {
    ...mapGetters(['isInternetConnected', 'getLoadingState']),
  },
  methods: {
    async handleLogin() {
      if (this.email === '' || this.password === '') {
        this.$toast.error('Missing username or password')

        return
      }
      try {
        const payload = {
          data: {
            email: this.email,
            password: this.password,
          },
        }

        const result = await this.$store.dispatch('loginUser', payload)

        if (result.data.status === 'success') {
          this.$toast.success('Login successful', {
            color: 'green',
            dismissable: true,
            queueable: true,
          })
          this.$store.commit('UPDATE_CURRENT_USER_EMAIL', this.email)
          this.$router.push({ name: 'Verification' })
        } else if (result.data.status === 'failed') {
          this.$toast.error('Incorrect email or password pair')
        }
      } catch (err) {
        console.log(err.message)

        this.$toast.error(
          'Error processing your loging request, please contact support'
        )
      }
    },
  },
}
</script>
<style scoped lang="scss">
.body {
  font-weight: 400;
}

.login-btn {
  width: 60%;
  margin-left: 20%;
}
.logo {
  width: 120px;
  height: 120px;
}
</style>
